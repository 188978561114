import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import zipdf from "../assets/zombieiguana.pdf"
import cpdf from "../assets/caligo.pdf"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <div className="container" style={{marginTop:'80px'}}>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Caligo Productions Bible</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Pitch bible for a production named 'Love Me Anyways'</p>
            <a href={cpdf} style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>View Project</button></a>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Zombie Iguana Productions Pitch Bible</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Pitch bible for a children's production named 'Chloe &amp; The Chameleon'</p>
            <a href={zipdf} style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>View Project</button></a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProjectsPage
